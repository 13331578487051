<template>
  <validation-observer ref="refFormObserver">
    <div>
      <div class="d-flex align-items-center mb-50">
        <!-- ANCHOR: Thêm hành khách -->
        <div class="mr-50">
          <AddPassenger
            :pax-lists="passengerDataToAdd"
            @changeQuantityPax="(val) => $emit('multipleQuantityPaxChange', val)"
          />
        </div>

        <!-- ANCHOR: Nhập nhanh -->
        <b-button
          id="id-icon-quick-input-multiple-pax"
          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
          class="py-50 px-2 d-flex-center rounded-lg"
          variant="gradient"
          @click="handleShowModalQuickInput"
        >
          <feather-icon
            class="text-white"
            icon="ZapIcon"
            size="17"
          />
          <!-- {{ isMobileView ? 'Nhập nhanh' : $t('flight.quickInputPax') }} -->
        </b-button>
      </div>

      <!-- SECTION - Passenger (Reservations Form) -->
      <b-card
        v-for="(passenger, index) in passengerDataToAdd"
        :key="index"
        class="rounded-lg mb-50 border"
        header-class="px-50 pb-50 pt-0"
        no-body
      >
        <!-- ANCHOR - HEADER -->
        <template #header>
          <b-row
            class="w-100 mx-0 add_spacing"
            no-gutters
          >
            <!-- ANCHOR - Name Pax + Details -->
            <div class="font-weight-bolder w-100 d-flex-between">
              <div class="d-flex">
                <span class="text-nowrap mr-2">
                  {{ index + 1 }}. {{ $t(`reservation.${passenger.paxType}`) }}<span class="text-danger">*</span>:
                </span>
                <b-form-checkbox
                  :id="`Check_SN-${passenger.paxType}-${index}`"
                  v-model="passenger.isSetBirthday"
                  :disabled="['INFANT'].includes(passenger.paxType) || (['CHILD'].includes(passenger.paxType) && ['1G'].includes(airlineSearch))"
                >
                  NS
                </b-form-checkbox>
              </div>

              <div class="d-flex justify-content-end w-100">
                <!-- <b-button
                  v-ripple.400="'rgba(47, 165, 229, 0.2)'"
                  v-b-tooltip.hover.v-primary
                  badge-classes="badge-info badge-glow"
                  title="Scan"
                  variant="flat-primary"
                  class="px-50 py-25"
                >
                  <feather-icon
                    icon="MaximizeIcon"
                    size="20"
                    class=""
                  />
                </b-button> -->

                <b-button
                  v-b-toggle="`collapse-${passenger.paxType}-${index}`"
                  v-ripple.400="'rgba(47, 165, 229, 0.2)'"
                  variant="flat-primary"
                  class="px-50 py-25 mr-2"
                >
                  <!-- <div
                    class="cursor-pointer"
                    :class="{ 'rotate-180': showDetails }"
                    @click="showDetails = !showDetails"
                  >
                    <feather-icon
                      icon="ChevronDownIcon"
                      size="24"
                    />
                  </div> -->
                  <span class="font-small-3 ml-25">
                    {{ $t('flight.Details') }}
                  </span>
                  <feather-icon
                    icon="ChevronDownIcon"
                    size="16"
                  />
                </b-button>

                <b-button
                  variant="flat-danger"
                  class="px-50 py-0"
                  @click="handleDeletePax(passenger)"
                >
                  <feather-icon
                    icon="XIcon"
                    size="16"
                    class=""
                  />
                </b-button>
              </div>
            </div>

            <!-- ANCHOR - Honorific -->
            <b-col cols="3">
              <validation-provider
                #default="validationContext"
                name="Honorific"
                rules="required"
                :vid="`Honorific-${passenger.paxType}-${index}`"
              >
                <b-form-group
                  size="sm"
                  class="mb-0"
                  :state="getValidationState(validationContext) === false ? false : null"
                >
                  <v-select
                    :id="`Honorific-${passenger.paxType}-${index}`"
                    v-model="passenger.title"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="['ADULT'].includes(passenger.paxType) ? [...titleOptions[passenger.paxType], ...titleOptions['CHILD']] : titleOptions[passenger.paxType]"
                    label="text"
                    class="honorific-select fw-700"
                    :clearable="false"
                    :placeholder="`${$t('flight.Honorific')} (*)`"
                    :reduce="val => val.value"
                  >
                    <template #option="data">
                      <span class="font-small-4">
                        {{ $t(data.text) }}
                      </span>
                    </template>

                    <template #selected-option="data">
                      <span class="text-nowrap font-medium-1">
                        {{ $t(data.text) }}
                      </span>
                    </template>
                  </v-select>

                  <b-tooltip
                    v-if="validationContext.errors[0]"
                    :target="`title-${passenger.paxType}-${index}`"
                    triggers="hover focus"
                    placement="top"
                    boundary="viewport"
                    variant="danger"
                    :title="validationContext.errors[0]"
                  >
                    <span class="text-white py-25 mb-0">
                      {{ validationContext.errors[0] }}
                    </span>
                  </b-tooltip>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- ANCHOR - Last name -->
            <b-col cols="4">
              <validation-provider
                #default="validationContext"
                rules="required|min:1|alphaSpaces"
                name="Last Name"
                :vid="`lastName-${passenger.paxType}-${index}`"
              >
                <vue-autosuggest
                  :id="`lastName-${passenger.paxType}-${index}`"
                  v-model="passenger.lastName"
                  :suggestions="passenger.paxType ? paxFilteredSuggestions[passenger.paxType] : []"
                  :input-props="{
                    placeholder: `${$t('flight.lastName')} (*)`,
                    state: `${getValidationState(validationContext) === false ? false : null}`,
                    class:`form-control text-body ${getValidationState(validationContext) === false ? 'is-invalid' : ''}`,
                    style: 'font-size: 17px !important; font-weight: 700 !important',
                  }"
                  :section-configs="sectionConfigs"
                  :get-suggestion-value="(suggestion) => getSuggestionValue(suggestion,'lastName')"
                  @focus="setCurrentPaxIndex(index)"
                  @input="$event => {
                    passenger.lastName = formatterInput($event);
                    debounceSearchCustomerContact(passenger.lastName);
                  }"
                >
                  <template slot-scope="{ suggestion }">
                    <div class="cursor-pointer">
                      <b-row>
                        <b-col
                          cols="12"
                          class="text-nowrap font-italic"
                        >
                          {{ suggestion.item.type ? $t(`reservation.${suggestion.item.type}`) : '' }}
                        </b-col>
                        <b-col cols="12">
                          <!-- eslint-disable-next-line vue/no-v-html -->
                          <b v-html="highlightSearchString(getLocaleFullName(suggestion.item), passenger.lastName)" />
                        </b-col>
                        <b-col
                          cols="12"
                          class="text-nowrap"
                        >
                          {{ suggestion.item.birthDay ? suggestion.item.birthDay.slice(0, 10) : '' }}
                        </b-col>
                      </b-row>
                    </div>
                  </template>
                </vue-autosuggest>

                <b-tooltip
                  v-if="validationContext.errors[0]"
                  :target="`lastName-${passenger.paxType}-${index}`"
                  triggers="hover focus"
                  placement="top"
                  boundary="viewport"
                  variant="danger"
                  :title="validationContext.errors[0]"
                >
                  <span class="text-white py-25 mb-0">
                    {{ validationContext.errors[0] }}
                  </span>
                </b-tooltip>
              </validation-provider>
            </b-col>

            <!-- ANCHOR - First name -->
            <b-col cols="5">
              <validation-provider
                #default="validationContext"
                name="First Name"
                rules="required|min:1|alphaSpaces"
                :vid="`firstName-${passenger.paxType}-${index}`"
              >
                <vue-autosuggest
                  :id="`firstName-${passenger.paxType}-${index}`"
                  v-model="passenger.firstName"
                  :suggestions="passenger.paxType ? paxFilteredSuggestions[passenger.paxType] : []"
                  :input-props="{
                    placeholder: `${$t('flight.firstName')} (*)`,
                    state: `${getValidationState(validationContext) === false ? false : null}`,
                    class:`form-control text-body ${getValidationState(validationContext) === false ? 'is-invalid' : ''}`,
                    style: 'font-size: 17px !important; font-weight: 700 !important',
                  }"
                  :section-configs="sectionConfigs"
                  :get-suggestion-value="(suggestion) => getSuggestionValue(suggestion,'firstName')"
                  @focus="setCurrentPaxIndex(index)"
                  @input="$event => {
                    passenger.firstName = formatterInput($event);
                    debounceSearchCustomerContact(passenger.firstName);
                  }"
                >
                  <template slot-scope="{ suggestion }">
                    <div class="cursor-pointer">
                      <b-row>
                        <b-col
                          cols="12"
                          class="text-nowrap font-italic small"
                        >
                          {{ suggestion.item.type ? $t(`reservation.${suggestion.item.type}`) : '' }}
                        </b-col>
                        <b-col cols="12">
                          <!-- eslint-disable-next-line vue/no-v-html -->
                          <b v-html="highlightSearchString(getLocaleFullName(suggestion.item), passenger.firstName)" />
                        </b-col>
                        <b-col
                          cols="12"
                          class="text-nowrap"
                        >
                          {{ suggestion.item.birthDay ? suggestion.item.birthDay.slice(0, 10) : '' }}
                        </b-col>
                      </b-row>
                    </div>
                  </template>
                </vue-autosuggest>

                <b-tooltip
                  v-if="validationContext.errors[0]"
                  :target="`firstName-${passenger.paxType}-${index}`"
                  triggers="hover focus"
                  placement="top"
                  boundary="viewport"
                  variant="danger"
                  :title="validationContext.errors[0]"
                >
                  <span class="text-white py-25 mb-0">
                    {{ validationContext.errors[0] }}
                  </span>
                </b-tooltip>
              </validation-provider>
            </b-col>

            <!-- ANCHOR - Birthday -->
            <b-col
              v-if="passenger.isSetBirthday"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                :name="$t('flight.dob')"
                :rules="(passenger.paxType === 'ADULT' && ['MSTR', 'MISS'].includes(passenger.title)) ? getValidateBirthday('CHILD') : getValidateBirthday(passenger.paxType)"
                :vid="`dob-${passenger.paxType}-${passenger.paxId}`"
              >
                <b-form-group
                  :id="`bd-${passenger.paxType}-${passenger.paxId}`"
                  class="mb-0"
                  style="max-width: 250px;"
                >
                  <flat-pickr
                    v-model="passenger.birthday"
                    class="d-none"
                  />
                  <div
                    class="form-control p-0"
                    :class="validationContext.errors[0] ? 'is-invalid' : ''"
                  >
                    <flat-pickr
                      :id="`dob-${passenger.paxType}-${passenger.paxId}`"
                      v-model="passenger.birthday"
                      :name="$t('flight.dob')"
                      class="form-control fw-700 px-50 py-0"
                      :config="{
                        ...configFlatPickr,
                        altInputClass: 'form-control text-body input fw-700 px-50 py-0 border-transparent',
                      }"
                      :style="`height: '30px'; font-size: '17px !important'`"
                      :placeholder="`${$t('flight.dob')} ${getValidateBirthday(passenger.paxType).includes('required') ? '(*)' : ''}`"
                    />
                  </div>
                  <b-tooltip
                    v-if="validationContext.errors[0]"
                    :target="`bd-${passenger.paxType}-${passenger.paxId}`"
                    triggers="hover focus"
                    placement="top"
                    boundary="viewport"
                    variant="danger"
                    :title="validationContext.errors[0]"
                  >
                    <span class="text-white py-25 mb-0">
                      {{ validationContext.errors[0] }}
                    </span>
                  </b-tooltip>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </template>

        <!-- SECTION - COLLAPSE -->
        <b-collapse
          :id="`collapse-${passenger.paxType}-${index}`"
          :visible="(passenger.paxType === 'INFANT') ? true : false"
          class="mb-50"
        >
          <!-- <div class="mx-50"> -->
          <!-- NOTE - NGƯỜI LỚN đi cùng -->
          <b-row
            class="my-25"
            no-gutters
          >
            <b-col
              v-if="passenger.paxType === 'INFANT'"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="parentId"
                :rules="(passenger.paxType === 'INFANT') ? `required|duplicateParentId:${parentSelected.toString()}` : ''"
                :vid="`parent-id-select-${passenger.paxId}`"
              >
                <b-form-group
                  class="mb-0 ml-50"
                  :state="getValidationState(validationContext) === false ? false : null"
                >
                  <v-select
                    v-if="passenger.paxType === 'INFANT'"
                    :id="`parent-id-select-${passenger.paxId}`"
                    v-model="passenger.parentPaxId"
                    :options="parentOptions"
                    :reduce="val=> val.paxId"
                    :clearable="false"
                    :placeholder="`${$t('flight.selectAdult')} (*)`"
                    class="fw-700"
                    style="max-width: 250px;"
                    label="firstName"
                  >
                    <template #option="data">
                      <span class="font-small-3">
                        {{
                          data.firstName && data.lastName
                            ? `${data.title} ${data.lastName} ${data.firstName}`
                            : `${data.paxType}`
                        }}
                      </span>
                    </template>
                    <template #selected-option="data">
                      <span class="font-small-3">
                        {{
                          data.firstName && data.lastName
                            ? `${data.title} ${data.lastName} ${data.firstName}`
                            : data.paxType ? `${data.paxType}` : 'Chọn người lớn đi cùng (*)'
                        }}
                      </span>
                    </template>
                  </v-select>
                  <b-tooltip
                    v-if="validationContext.errors[0]"
                    :target="`parent-id-select-${passenger.paxId}`"
                    triggers="hover focus"
                    placement="top"
                    boundary="viewport"
                    variant="danger"
                    :title="validationContext.errors[0]"
                  >
                    <span class="text-white py-25 mb-0">
                      {{ validationContext.errors[0] }}
                    </span>
                  </b-tooltip>
                  <div class="pt-25">
                    <i class="text-warning font-small-4">
                      <span class="text-danger">*</span> {{ $t('flight.requiredAdult') }}
                    </i>
                  </div>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- NOTE - MembershipCard -->
            <b-col
              v-if="passenger.paxType !== 'INFANT' && !isEmpty(selectedTrip.flat()) && selectedTrip.flat().some(t => !!t && ['VN'].includes(t.airline))"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="Member Fly"
                rules=""
                :vid="`memberFly-${passenger.paxType}-${index}`"
              >
                <b-input-group
                  :id="`memberFly-${passenger.paxType}-${index}`"
                  class="border rounded d-flex"
                >
                  <b-input-group-prepend
                    :id="`memberFly-${passenger.paxType}-${index}`"
                    class="flex-nowrap"
                  >
                    <v-select
                      :id="`memberFly-${passenger.paxType}-${index}`"
                      v-model="passenger.memberFly.airline"
                      :options="getAirlineOptionsGroupByTrips(index)"
                      style="min-width: 180px;"
                      label="text"
                      class="membership-airline-select border-right"
                      :class="isMobileView ? 'border-secondary' : ''"
                      :clearable="false"
                      :placeholder="`${$t('flight.airline')}`"
                      :reduce="val => val.value"
                    >
                      <template #option="data">
                        <span class="font-small-3 my-25 font-weight-bold">
                          {{ data.text }}
                        </span>
                      </template>
                      <template #selected-option="data">
                        <span class="font-small-3 font-weight-bolder text-nowrap">
                          {{ data.text }}
                        </span>
                      </template>
                    </v-select>
                  </b-input-group-prepend>

                  <b-form-input
                    :id="`cardNumber-${passenger.paxType}-${index}`"
                    v-model="passenger.memberFly.cardNumber"
                    style="min-width: 145px;"
                    type="number"
                    class="border-transparent fw-700"
                    :placeholder="`${$t('flight.memberFly')}`"
                    lazy-formatter
                    :formatter="formatterCardNumber"
                    :state=" getValidationState(validationContext) === false ? false : null"
                    @change="val => passenger.memberFly.cardNumber = Number(val)"
                  />
                </b-input-group>
              </validation-provider>
            </b-col>

            <!-- ANCHOR Modal RegisterMembership -->
            <!-- <b-button
                v-if="!isEmpty(selectedTrip) && selectedTrip.some(trip => trip && ['1S','QH'].includes(trip.source))"
                :id="`memberFlyBtn-${passenger.paxType}-${index}`"
                variant="flat-info"
                class="py-25"
                :class="(passenger.paxType !== 'ADULT') ? 'd-none' : 'd-block'"
                @click="handleOpenModalRegisterMembershipCard({index, passenger}, $event)"
              >
                <div class="d-flex-center">
                  <feather-icon
                    icon="PlusSquareIcon"
                    size="20"
                    class="mr-50"
                  />
                  <div>{{ $t('flight.registerMembershipCard') }}</div>
                </div>
              </b-button> -->
          </b-row>

          <!-- NOTE - PASSPORT -->
          <span
            v-if="!isDomesticTrips && (passenger.paxType !== 'INFANT')"
            class="fw-700 ml-50"
          >
            Hộ chiếu
          </span>
          <b-row
            v-if="!isDomesticTrips && (passenger.paxType !== 'INFANT')"
            no-gutters
            class="px-25"
          >
            <!-- ANCHOR Passport number -->
            <b-col
              md="3"
              lg="6"
              class="px-25 pl-lg-0 mt-50 pr-lg-50"
            >
              <validation-provider
                #default="validationContext"
                :rules="getValidatePassport()"
                :name="$t('flight.Passport')"
                :vid="`passport-${passenger.paxType}-${index}`"
              >
                <b-form-input
                  :id="`passport-${passenger.paxType}-${index}`"
                  v-model="passenger.PaxDocuments[0].number"
                  :state="getValidationState(validationContext) === false ? false : null"
                  :placeholder="`${$t('flight.Passport')} ${getValidatePassport().includes('required') ? '(*)' : ''}`"
                />
              </validation-provider>
            </b-col>

            <!-- ANCHOR Nationality -->
            <b-col
              md="3"
              lg="6"
              class="px-50 mt-50 px-lg-0"
            >
              <validation-provider
                #default="validationContext"
                :rules="getValidatePassport()"
                :name="$t('flight.Nationality')"
                :vid="`nationality-${passenger.paxType}-${index}`"
              >
                <b-form-group
                  size="sm"
                  class="mb-0"
                >
                  <vue-autosuggest
                    :id="`nationality-${passenger.paxType}-${index}`"
                    v-model="passenger.PaxDocuments[0].nationality"
                    :suggestions="nationalSuggestion"
                    :input-props="{
                      placeholder: 'Mã quốc gia',
                      state: `${getValidationState(validationContext) === false ? false : null}`,
                      maxlength:'30',
                      class:`form-control`
                    }"
                    :section-configs="nationalConfigs"
                    :get-suggestion-value="(suggestion) => getSuggestionNationalValue(suggestion)"
                    @focus="handleFocusNationalSuggestion(index)"
                    @input="inputHandle(passenger.PaxDocuments[0].nationality)"
                  >
                    <template slot-scope="{suggestion}">
                      <div class="cursor-pointer">
                        <b-row>
                          <b-col cols="12">
                            <b>{{ suggestion.item.countryName }}</b>
                          </b-col>
                          <b-col
                            cols="12"
                            class="text-nowrap"
                          >
                            (<span class="text-warning font-weight-bolder">{{ suggestion.item.longCode }}</span>)
                          </b-col>
                        </b-row>
                      </div>
                    </template>
                  </vue-autosuggest>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- ANCHOR passport Nơi cấp -->
            <b-col
              md="3"
              lg="6"
              class="px-25 mt-50 pr-lg-50"
            >
              <validation-provider
                #default="validationContext"
                :rules="getValidatePassport()"
                name="Nơi cấp"
                :vid="`passport-place-issued-${passenger.paxType}-${index}`"
              >
                <vue-autosuggest
                  :id="`passport-place-issued-${passenger.paxType}-${index}`"
                  v-model="passenger.PaxDocuments[0].isCountry"
                  :suggestions="nationalSuggestion"
                  :input-props="{
                    placeholder: 'Nơi cấp',
                    state: `${getValidationState(validationContext) === false ? false : null}`,
                    maxlength:'30',
                    class:`form-control`
                  }"
                  :section-configs="placeIssuedConfigs"
                  :get-suggestion-value="(suggestion) => getSuggestionNationalValueNationalName(suggestion)"
                  @focus="getSuggestionNationalValueNationalName(index)"
                  @input="inputHandle(passenger.PaxDocuments[0].isCountry)"
                >
                  <template slot-scope="{suggestion}">
                    <div class="cursor-pointer">
                      <b-row>
                        <b-col cols="12">
                          <b>{{ suggestion.item.countryName }}</b>
                        </b-col>
                      </b-row>
                    </div>
                  </template>
                </vue-autosuggest>
              </validation-provider>
            </b-col>

            <!-- ANCHOR passport expDate -->
            <b-col
              md="3"
              lg="6"
              class="px-25 mt-50 px-lg-0"
            >
              <validation-provider
                #default="validationContext"
                :rules="getValidatePassport()"
                :name="$t('flight.expirationDate')"
                :vid="`passport-expirationDate-${passenger.paxType}-${index}`"
              >
                <b-form-group
                  :id="`passport-exDate-${passenger.paxType}-${index}`"
                  class="mb-0"
                >
                  <flat-pickr
                    v-model="passenger.PaxDocuments[0].endDate"
                    class="d-none"
                  />
                  <div
                    class="form-control p-0"
                    :class="validationContext.errors[0] ? 'is-invalid' : ''"
                  >
                    <flat-pickr
                      :id="`passport-expirationDate-${passenger.paxType}-${index}`"
                      v-model="passenger.PaxDocuments[0].endDate"
                      :name="$t('flight.expirationDate')"
                      class="form-control px-50 py-0"
                      :config="{
                        ...configFlatPickrPassport,
                        altInputClass: 'form-control input px-50 py-0 border-transparent',
                      }"
                      :style="{height: '30px'}"
                      :placeholder="`${$t('flight.expirationDate')} ${getValidatePassport().includes('required') ? '(*)' : ''}`"
                    />
                  </div>
                  <b-tooltip
                    v-if="validationContext.errors[0]"
                    :target="`passport-exDate-${passenger.paxType}-${index}`"
                    triggers="hover focus"
                    placement="top"
                    boundary="viewport"
                    variant="danger"
                    :title="validationContext.errors[0]"
                  >
                    <span class="text-white py-25 mb-0">
                      {{ validationContext.errors[0] }}
                    </span>
                  </b-tooltip>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <!-- ANCHOR: Thêm cảnh báo "hộ chiếu sắp hết hạn" nếu thời gian hết hạn hộ chiếu < thời gian bay 6 tháng (https://discord.com/channels/1054696448110903327/1300290501815308328/1300290507137875971) -->
          <div
            v-if="isLastFlightDateAfterPassportExpiry(passenger.PaxDocuments[0].endDate, flightMaxDate)"
            class="text-danger mx-1 my-50"
          >
            <small>{{ $t('reservation.isLastFlightDateAfterPassportExpiry') }}</small>
          </div>
          <div
            v-else-if="flightMaxDate && checkPassportLessThan6MonthsBeforeFlightDate(passenger.PaxDocuments[0].endDate, flightMaxDate)"
            class="text-danger mx-1 my-50"
          >
            <small>{{ $t('reservation.passportLessThan6MonthsBeforeFlight') }}</small>
          </div>
          <!-- </div> -->
        </b-collapse>
      </b-card>

      <ModalRegisterMembershipCard
        :member-fly-data.sync="memberFlyData"
        :data-search-flight="dataSearchFlight"
        :pax-data="paxDataSelect"
        :airline-lists="['VN']"
        :place="'create_booking'"
      />
      <ModalQuickInputMultiPax
        :max-adults="dataSearchFlight.adult"
        :max-children="dataSearchFlight.child"
        :max-infants="dataSearchFlight.infant"
        @addMultiplePassengers="addMultiplePassengers"
      />
    </div>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
  BButton,
  BCollapse,
  VBToggle,
  VBTooltip,
  BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { VueAutosuggest } from 'vue-autosuggest'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import isNumber from 'lodash/isNumber'
import Ripple from 'vue-ripple-directive'
import {
  computed, ref, watch, provide,
} from '@vue/composition-api'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Vietnamese } from 'flatpickr/dist/l10n/vn'

import { skyTeamAirlineGroupOptions } from '@/constants/selectOptions'
import { titleOptions } from '@/constants/reservation'
import { apiReservation, apiCustomer } from '@/api'
import { getLocaleFullName } from '@/views/apps/invoices/useInvoice'
import VueI18n from '@/libs/i18n'
import store from '@/store'
import {
  checkPassportLessThan6MonthsBeforeFlightDate, getMaxTime, isLastFlightDateAfterPassportExpiry,
} from '@/@core/utils/filter'

import { removeAccents } from '@core/comp-functions/forms/formatter-input'
import formValidation from '@core/comp-functions/forms/form-validation'

import useClassBookingHandle from '@flightv2/result-class-booking/useClassBookingHandle'

import {
  required, min, max, duplicateParentId, alphaSpaces,
} from '@validations'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormCheckbox,
    BFormInput,
    BTooltip,
    BCollapse,

    vSelect,
    VueAutosuggest,
    flatPickr,

    ValidationObserver,
    ValidationProvider,

    AddPassenger: () => import('./AddPassenger.vue'),
    ModalRegisterMembershipCard: () => import('@flightv2/reservation/ModalRegisterMembershipCard.vue'),
    ModalQuickInputMultiPax: () => import('@flightv2/reservation/ModalQuickInputMultiPax.vue'),
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    customerId: {
      type: Number,
      default: 0,
      required: true,
    },
    passengerDataToAdd: {
      type: Array,
      required: true,
    },
    dataSearchFlight: {
      type: Object,
      default: () => {},
    },
    selectedTrip: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { refFormObserver, getValidationState } = formValidation()
    const dataTrips = computed(() => store.getters['app-flight-v2/getResultSearchClassBooking'])
    const isDomesticTrips = computed(() => dataTrips.value && dataTrips.value.every(item => item.isDomestic === true))
    const {
      airlineSearch,
    } = useClassBookingHandle()
    // format input
    function formatterInput(value) {
      const str = removeAccents(value)
      return str.toUpperCase().trim()
    }

    function formatterCardNumber(value) {
      const str = Number(value)
      if (!str) return null
      return Number(value)
    }

    const parentOptions = computed(() => props.passengerDataToAdd.filter(parent => parent.paxType === 'ADULT'))
    const parentSelected = computed(() => props.passengerDataToAdd.filter(item => item.parentPaxId).map(i => parseInt(i.parentPaxId, 10)))

    const getValidateBirthday = paxType => {
      const {
        type, flights, flight, source,
      } = props.dataSearchFlight

      if (flights || flight) {
        const departDate = flight ? flight.departDate : flights[0].departDate
        const validationRules = {
          ADULT: `ageGroup:${type},${departDate},${paxType}`,
          CHILD: `ageGroup:${type},${departDate},${paxType}${source && ['1G'].includes(source) ? '|required' : ''}`,
          INFANT: `required|ageGroup:${type},${departDate},${paxType}`,
        }
        return validationRules[paxType]
      }
      return ''
    }

    // eslint-disable-next-line arrow-body-style
    const getValidatePassport = () => {
      // const sources = props.selectedTrip.map(item => item.source)
      // if (sources.includes('1G')) { return 'required' }
      return ''
    }

    function getAirlineOptionsGroupByTrips(index) {
      const result = new Set()
      const addedValues = new Set() // Check giá trị trước khi thêm vào result
      const AIRLINE = 'VN'

      // eslint-disable-next-line vue/no-mutating-props
      props.passengerDataToAdd[index].memberFly.airline = AIRLINE

      skyTeamAirlineGroupOptions.forEach(item => {
        result.add(item)
        addedValues.add(item.value)
      })
      if (!addedValues.has(AIRLINE)) { // Kiểm tra xem giá trị đã được thêm vào result chưa
        const text = store.getters['globalConfig/getAirlineNameByCode'](AIRLINE) || AIRLINE
        result.add({ value: AIRLINE, text })
        addedValues.add(AIRLINE)
      }
      return Array.from(result)
    }

    const memberFlyData = ref(null)
    const indexMemberFly = ref(null)
    const paxDataSelect = ref(null)

    watch(() => memberFlyData.value, data => {
      if (data) {
        const checkData = data.filter(item => isNumber(item.cardNumber) && (item.cardNumber !== 0))

        if (checkData.length === 1) {
          if (['VN'].includes(checkData[0].airline.code)) {
            // eslint-disable-next-line vue/no-mutating-props
            props.passengerDataToAdd[indexMemberFly.value].memberFly.cardNumber = data[0].cardNumber
            // eslint-disable-next-line vue/no-mutating-props
            props.passengerDataToAdd[indexMemberFly.value].memberFly.airline = data[0].airline.code
          }
        }

        if (checkData.length > 1) {
          const findAirline = checkData.find(a => ['VN'].includes(a.airline.code))
          if (findAirline) {
            // eslint-disable-next-line vue/no-mutating-props
            props.passengerDataToAdd[indexMemberFly.value].memberFly.cardNumber = findAirline.cardNumber
            // eslint-disable-next-line vue/no-mutating-props
            props.passengerDataToAdd[indexMemberFly.value].memberFly.airline = findAirline.airline.code
          }
        }
        // eslint-disable-next-line vue/no-mutating-props
        props.passengerDataToAdd[indexMemberFly.value].memberFly.infoFlyCardNumbers = checkData
      }
    }, { deep: true })

    // async function handleOpenModalRegisterMembershipCard(data, event) {
    //   await new Promise(resolve => {
    //     indexMemberFly.value = data.index
    //     paxDataSelect.value = data.passenger
    //     resolve()
    //   })

    //   this.$root.$emit('bv::show::modal', 'modal-register-member-ship-card', event)
    // }

    function addMultiplePassengers(paxs) {
      const {
        ADULT: adultsArray,
        CHILD: childrenArray,
        INFANT: infantsArray,
      } = paxs

      const {
        adult: adultsCount,
        child: childrenCount,
        infant: infantsCount,
      } = props.dataSearchFlight

      const addingAdultsCount = Math.min(adultsArray.length, adultsCount)
      const addingChildrenCount = Math.min(childrenArray.length, childrenCount)
      const addingInfantsCount = Math.min(infantsArray.length, infantsCount)

      if (addingAdultsCount) {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < addingAdultsCount; index++) {
          const addingPax = adultsArray[index]
          const pax = props.passengerDataToAdd[index]
          pax.title = addingPax.title
          pax.firstName = addingPax.firstName
          pax.lastName = addingPax.lastName
          pax.birthday = addingPax.birthday
        }
      }

      if (addingChildrenCount) {
        const startIndex = adultsCount
        const endIndex = startIndex + addingChildrenCount
        // eslint-disable-next-line no-plusplus
        for (let index = startIndex; index < endIndex; index++) {
          const childrenArrayIndex = index - startIndex
          const addingPax = childrenArray[childrenArrayIndex]
          const pax = props.passengerDataToAdd[index]
          pax.title = addingPax.title
          pax.firstName = addingPax.firstName
          pax.lastName = addingPax.lastName
          pax.birthday = addingPax.birthday
        }
      }

      if (addingInfantsCount) {
        const startIndex = adultsCount + childrenCount
        const endIndex = startIndex + addingInfantsCount
        // eslint-disable-next-line no-plusplus
        for (let index = startIndex; index < endIndex; index++) {
          const infantsArrayIndex = index - startIndex
          const addingPax = infantsArray[infantsArrayIndex]
          const pax = props.passengerDataToAdd[index]
          pax.title = addingPax.title
          pax.firstName = addingPax.firstName
          pax.lastName = addingPax.lastName
          pax.birthday = addingPax.birthday
        }
      }
    }

    const paxSuggestions = ref([])

    const getFilteredSuggestion = paxType => {
      if (!paxSuggestions.value || !paxSuggestions.value.length) return []

      if (!paxType) return paxSuggestions.value

      return [{ data: paxSuggestions.value[0].data.filter(pax => pax.type === paxType) }]
    }

    const paxFilteredSuggestions = computed(() => ({
      ADULT: getFilteredSuggestion('ADULT'),
      CHILD: getFilteredSuggestion('CHILD'),
      INFANT: getFilteredSuggestion('INFANT'),
    }))

    const fetchContactsPayload = {
      size: 10,
      page: 1,
      searchText: '',
    }
    let currentPaxIndex

    const setCurrentPaxIndex = currentIndex => {
      currentPaxIndex = currentIndex
    }

    const debounceSearchCustomerContact = debounce(searchText => {
      const customerId = props.customerId
      if (!customerId) return

      fetchContactsPayload.searchText = searchText
      apiCustomer.fetchContacts(customerId, fetchContactsPayload)
        .then(res => {
          paxSuggestions.value = [{ data: res.data.items }]
        })
    }, 500)

    const sectionConfigs = ref({
      default: {
        onSelected: selected => {
          const selectedItem = selected.item
          const currentPax = props.passengerDataToAdd[currentPaxIndex]
          currentPax.firstName = selectedItem.firstName || ''
          currentPax.lastName = selectedItem.lastName || ''
          // NOTE: 'birthday' !== 'birthDay'
          currentPax.birthday = selectedItem.birthDay ? selectedItem.birthDay.slice(0, 10) : ''
          currentPax.title = currentPax.title ? currentPax.title : selectedItem.gender || ''
        },
      },
    })

    const highlightSearchString = (fullName, searchString) => {
      if (!searchString) return fullName

      const regex = new RegExp(searchString, 'gi')
      const highlightedName = fullName.replace(regex, match => `<span class="text-danger">${match}</span>`)

      return highlightedName
    }

    function getSuggestionValue(suggestion, itemKey) {
      return suggestion.item[itemKey]
    }

    watch(() => props.customerId, value => {
      if (value) {
        debounceSearchCustomerContact('')
      }
    })

    const locale = computed(() => VueI18n.locale).value

    const configFlatPickr = ref({
      dateFormat: 'Y-m-d',
      minDate: '1.1.1920',
      maxDate: 'today',
      locale: locale === 'vi' ? Vietnamese : null,
      allowInput: true,
      altInput: true,
      altFormat: 'd-m-Y',
      shorthandCurrentMonth: true,
      disableMobile: true,
    })

    const configFlatPickrPassport = ref({
      dateFormat: 'Y-m-d',
      minDate: 'today',
      maxDate: '',
      locale: locale === 'vi' ? Vietnamese : null,
      allowInput: true,
      altInput: true,
      altFormat: 'd-m-Y',
      shorthandCurrentMonth: true,
      disableMobile: true,
    })

    const nationalSuggestion = ref([])

    const inputHandle = debounce(input => {
      apiReservation.getCountriesName({ searchText: input }).then(res => {
        nationalSuggestion.value = [{ data: res.data.items }]
      })
    }, 500)

    if (isEmpty(nationalSuggestion.value)) {
      inputHandle('')
    }

    function getSuggestionNationalValue(suggestion) {
      return suggestion?.item?.countryName
    }

    function getSuggestionNationalValueNationalName(suggestion) {
      return suggestion?.item?.countryName
    }

    function handleFocusNationalSuggestion(index) {
      setCurrentPaxIndex(index)

      if (isEmpty(nationalSuggestion.value)) {
        inputHandle('')
      }
    }

    const nationalConfigs = ref({
      default: {
        onSelected: selected => {
          const passengerSelect = props.passengerDataToAdd[currentPaxIndex]
          passengerSelect.PaxDocuments[0].nationality = selected.item.longCode
        },
      },
    })

    const placeIssuedConfigs = ref({
      default: {
        onSelected: selected => {
          const passengerSelect = props.passengerDataToAdd[currentPaxIndex]
          passengerSelect.PaxDocuments[0].isCountry = selected.item.longCode
        },
      },
    })

    const hidePopoverChangeQuantityPaxEvent = ref(false)

    function handleDeletePax(passenger) {
      hidePopoverChangeQuantityPaxEvent.value = !hidePopoverChangeQuantityPaxEvent.value
      emit('deletePassenger', passenger)
    }

    function handleShowModalQuickInput() {
      hidePopoverChangeQuantityPaxEvent.value = !hidePopoverChangeQuantityPaxEvent.value
      this.$bvModal.show('id-popup-quick-input-multiple-pax')
    }

    provide('hidePopoverChangeQuantityPaxEvent', hidePopoverChangeQuantityPaxEvent)

    const flightMaxDate = computed(() => {
      const allDepartureDate = props.reservationsData?.itineraries?.flat()?.map(item => item?.departure?.at)
      if (!allDepartureDate) return []
      return getMaxTime(allDepartureDate)
    })
    return {
      titleOptions,
      refFormObserver,
      getValidationState,
      getValidateBirthday,
      getValidatePassport,
      isLastFlightDateAfterPassportExpiry,
      configFlatPickr,
      configFlatPickrPassport,
      airlineSearch,
      flightMaxDate,
      // Validations
      required,
      min,
      max,
      duplicateParentId,
      alphaSpaces,
      formatterInput,
      formatterCardNumber,
      Vietnamese,
      isEmpty,

      parentOptions,
      parentSelected,
      // handleOpenModalRegisterMembershipCard,
      indexMemberFly,
      memberFlyData,
      paxDataSelect,
      addMultiplePassengers,

      getLocaleFullName,
      paxFilteredSuggestions,
      setCurrentPaxIndex,
      debounceSearchCustomerContact,
      sectionConfigs,
      highlightSearchString,
      getSuggestionValue,

      isDomesticTrips,
      getSuggestionNationalValue,
      nationalSuggestion,
      inputHandle,
      nationalConfigs,
      placeIssuedConfigs,
      handleFocusNationalSuggestion,
      getSuggestionNationalValueNationalName,

      getAirlineOptionsGroupByTrips,

      handleDeletePax,
      handleShowModalQuickInput,
      checkPassportLessThan6MonthsBeforeFlightDate,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.border-transparent {
  border-color: transparent !important;
  background-color: transparent !important;
}
</style>

<style lang="scss" scoped>
.membership-airline-select ::v-deep {
  .vs__dropdown-toggle {
    border: 0px !important;
  }

  ul.vs__dropdown-menu {
    min-width: 300px !important;
  }
//   .vs__search {
//   margin-top: 0px !important;
// }
}

.add_spacing {
  & > * {
    padding-right: 0.3rem;
    margin-top: 0.4rem;
  }
}

.honorific-select ::v-deep {
  .vs__dropdown-toggle {
    height: 37.99px !important;
    padding: .2rem;
    input.vs__search {
      padding: 0 !important;
    }
  }
}
</style>
